import * as React from "react";
import Container from "../../components/container";
import Layout from "../../components/layout";

// import { StaticImage } from "gatsby-plugin-image";
//import HubspotForm from "react-hubspot-form";
import {
  mainContent,
  introSection,
  buttonGroup,
  row,
  //col6,
  col12,
  // marginTopSubstitute,
  //hubSpotForm,
} from "../subpage.module.scss";

const DMEA25Page = () => {
  return (
    <Layout pageTitle="DMEA25" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                <h1>
                  DMEA - Fachmesse Connecting Digital Health in Berlin steht vor
                  der Tür.
                </h1>

                <p className="text-medium">
                  Besuchen Sie uns vom 08. bis 10. April 2025 am Schweizer Stand
                  in Halle 6.2, Stand C-106.{" "}
                </p>
              </div>

              <div className={col12}>
                <h3>Diesjährige Themen bei uns am Stand</h3>
                <p>
                  Entdecken Sie dabei unsere neuesten Entwicklungen in den
                  Bereichen Medizinische Interoperabilitätsplattform und die
                  darauf basierende TIE Portalewelt, mit den Komponenten
                  Patientenportal, Zuweiserportal, Klinikportal und
                  Verlegungsportal, für eine umfassende und effiziente
                  Patientenversorgung.
                </p>
                <p>
                  Diese Neuerungen und weitere Module der health-engine® stellen
                  wir Ihnen auch gerne anhand von Live-Demos vor.
                </p>
                <div style={{ marginBottom: 40 }} className={buttonGroup}>
                  <a
                    href="https://tie.ch/portale"
                    className="button-outline blue"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Mehr Info zur TIE Portalwelt{" "}
                  </a>{" "}
                </div>
              </div>

              <div className={col12}>
                <h3>
                  Haben Sie Interesse an einem persönlichem Gespräch mit uns?
                </h3>
                <p>
                  Treffen Sie unsere Experten am Schweizer Stand an der DMEA in
                  Halle 6.2, Stand C-106 für ein persönliches Gespräch. Buchen
                  Sie am besten heute noch Ihren Termin. Wir freuen uns auf den
                  Austausch mit Ihnen.
                </p>
                <div style={{ marginBottom: 40 }} className={buttonGroup}>
                  <a
                    href="https://outlook.office365.com/owa/calendar/DMEA2024@tie.ch/bookings/"
                    className="button-outline blue"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Termin buchen{" "}
                  </a>{" "}
                </div>
              </div>

              <div className={col12}>
                <p>
                  Bleiben Sie über neueste Informationen und Veranstaltungen von
                  the i-engineers stets informiert und melden Sie sich für
                  unseren Newsletter an.
                </p>
                <div className={buttonGroup}>
                  <a
                    href="https://www.tie.ch/landingpages/newsletter-anmeldung"
                    className="button-outline blue"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Zur Newsletter Anmeldung{" "}
                  </a>{" "}
                </div>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default DMEA25Page;
